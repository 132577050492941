


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
// import FormSwitch from '@/components/form/form-switch.vue';
// import priceCalculator from '@/service/price-calculator';
// import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { inclusionOfClientDamages, artGeboudeItemsAbu } from '@/constant/constants';
import { copyObject, onCurrencyFocus } from '@/helper/functions';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

const BeschreibungObjektItem = {
  name: '',
  Kurzbeschreibung: null,
  BeitragssatzInPromile: null,
  Versicherungssumme: null,
};
const GrundwertItem = {
  name: '',
  Grundwert: null,
  neuerWert: null,
};

type additionalItemType = {
  name: string | null;
  Grundwert: string | null;
  neuerWert: string | null;
};
const GrundwertAdditionalItem = {
  name: null,
  Grundwert: null,
  neuerWert: null,
};
const AltbauNachAZ6155Item = {
  name: '',
  BeitragssatzPromile: null,
  VSinEUR: null,
  SBinEUR: null,
  SBinPercent: null,
  EinschlussSturm: null,
  EinschlussBauherrenrisiko: null,
  Strabe: null,
  PLZ: null,
  Ort: null,
};

const sachenImGefahrenbereichObject = {
  SachenImGefahrenbereich: 'nein',
  Variante: null,
  BenannteBaulichkeiten: null,
  Erstrisikosumme: null,
  BeitragssatzZuschlag: null,
  Zuschlag: null,
  Umkreis: 0,
  SelbstbeteiligungPercent: null,
  SBmindEUR: null,
};

const aZ6364EinschlussAuftraggeberschadenObject = {
  Variante: null,
  GefahrteilungAbweichendVonVOBTeilB: null,
};

const mitversicherungGewasserrisikoObject = {
  MitversicherungAZ5260: 'nein',
  Variante: null,
  SelbstbehaltInpercentn: null,
  SelbstbehaltMinimum: null,
};

const nachhaftungObject = {
  MitversicherungNachhaftung: 'nein',
  Variante: null,
  Monate: null,
};

const jahreshochstentschadigungNaturgefahrenGemabAZ6793Object = {
  JHEfurNaturgefahren: null,
};

const altbauNachAZ6155Object = {
  value: null,
  items: {
    AltbauIndividualA: {
      ...AltbauNachAZ6155Item,
      name: 'Altbau Individual A',
    },
    AltbauIndividualB: {
      ...AltbauNachAZ6155Item,
      name: 'Altbau Individual B',
    },
    AltbauIndividualC: {
      ...AltbauNachAZ6155Item,
      name: 'Altbau Individual C',
    },
  },
  Beschreibung: null,
};

export default Vue.extend({
  name: 'bauleistung-calc-2b-ABU',
  mixins: [dataChangeMixin],
  components: {
    // vSelect,
    // FormSwitch,
  },
  data() {
    return {
      name: 'bauleistung-insurance-basic',
      key: 'CONSTRUCTION_WORK_ABU_INSURANCE',
      backUrl: 'bauleistung-insurance',
      Beschreibung: {
        ArtDerBaumabnahme: null,
        ArtGeboude1: null,
        ArtGeboude2: null,
        Baubeschreibung: null,
        BeschreibungObjektItems: {
          Objekt1: {
            ...BeschreibungObjektItem,
            name: 'Objekt 1',
          },
          Objekt2: {
            ...BeschreibungObjektItem,
            name: 'Objekt 2',
          },
          Objekt3: {
            ...BeschreibungObjektItem,
            name: 'Objekt 3',
          },
          Objekt4: {
            ...BeschreibungObjektItem,
            name: 'Objekt 4',
          },
          Objekt5: {
            ...BeschreibungObjektItem,
            name: 'Objekt 5',
          },
        },
        Vorsteuerabzugsberechtigt: null,
        BereitsVerbauteLeistung: null,
      },
      Selbstbeteiligung: {
        TarifSB: {
          name: 'Tarif-SB',
          SelbstbehaltInpercent: null,
          GenerelleSelbstbeteiligung: null,
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          SelbstbehaltInpercent: null,
          GenerelleSelbstbeteiligung: null,
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          SelbstbehaltInpercent: null,
          GenerelleSelbstbeteiligung: null,
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          SelbstbehaltInpercent: null,
          GenerelleSelbstbeteiligung: null,
        },
      },
      Grundungsmabnahmen: {
        BerlinerVerbau: {
          name: 'Berliner Verbau',
          value: null,
        },
        Spundwande: {
          name: 'Spundwände',
          value: null,
        },
        Bohrpfahlwande: {
          name: 'Bohrpfahlwände',
          value: null,
        },
        Schlitzwande: {
          name: 'Schlitzwände',
          value: null,
        },
        HDIWande: {
          name: 'HDI-Wände',
          value: null,
        },
        HangverbauBoschungsabst: {
          name: 'Hangverbau/Böschungsabst.',
          value: null,
        },
        WasserhaltungAndGWAbsenkung: {
          name: 'Wasserhaltung, GW-Absenkung',
          value: null,
        },
        GeklGeschwWasserDicht: {
          name: 'Gekl./Geschw. Wasser. Dicht.',
          value: null,
        },
        BaugrundverbChemVerf: {
          name: 'Baugrundverb. Chem.Verf.',
          value: null,
        },
        PfahlBrunnenSenkgrundung: {
          name: 'Pfahl-, Brunnen-, Senkgründung',
          value: null,
        },
        HDISohlenUnterwBetonsohlen: {
          name: 'HDI-Sohlen, Unterw.betonsohlen',
          value: null,
        },
        Erdwarmeborhungen100m400m: {
          name: 'Erdwärmeborhungen > 100m <400m',
          value: null,
        },
      },
      MitversicherungBesondererBaumabnahmen: {
        MedizinLabortechnischeAnlagen: {
          name: 'Medizin-/labortechnische Anlagen',
          value: null,
        },
      },
      Erstrisikosummen: {
        items: {
          BaugrundBodenmassen: {
            ...GrundwertItem,
            name: 'Baugrund/Bodenmassen',
          },
          HilfsbautenUndBauhilfsstoffe: {
            ...GrundwertItem,
            name: 'Hilfsbauten und Bauhilfsstoffe',
          },
          ArbeitsgerusteBauzaune: {
            ...GrundwertItem,
            name: 'Arbeitsgerüste; Bauzäune',
          },
          BautafelERS: {
            ...GrundwertItem,
            name: 'Bautafel ERS',
          },
          Schadensuchkosten: {
            ...GrundwertItem,
            name: 'Schadensuchkosten',
          },
          ZusatzlicheAufraumkosten: {
            ...GrundwertItem,
            name: 'Zusätzliche Aufräumkosten',
          },
          ZusatzlAufraumkostenMitF: {
            ...GrundwertItem,
            name: 'Zusätzl. Aufräumkosten mit F',
          },
          BeschleunigungskUberst: {
            ...GrundwertItem,
            name: 'Beschleunigungsk./Überst.',
          },
          KostenEilExpressLuftfracht: {
            ...GrundwertItem,
            name: 'Kosten Eil-,Express-/Luftfracht',
          },
        },
        additionalItems: [] as additionalItemType[],
      },
      AZ6365VNAuftraggeber: {
        Tiefbaurisiko: null,
        GefahrteilungAbweichendVonVOBTeilB: null,
      },
      AZ6364EinschlussAuftraggeberschaden: copyObject(aZ6364EinschlussAuftraggeberschadenObject),
      Deckungseinschrankungen: {
        AbschlussAZ6761: null,
      },
      Deckungserweiterungen: {
        VerlusteDurchDiebstahl: null,
        AZ6868RuckgriffSubuntern: null,
        AZ6236InnereUnruhen: null,
        AZ6237StreikAussperrung: null,
        GrobeFahrlassigkeit: null,
        Insolvenzrisiko: null,
      },
      MitversicherungGewasserrisiko: copyObject(mitversicherungGewasserrisikoObject),
      Nachhaftung: copyObject(nachhaftungObject),
      MitversicherungVonFeuer: {
        MitversicherungVonFeuer: 'nein',
        BaudauerInMonaten: null,
        Baunebenkosten: null,
      },
      JahreshochstentschadigungNaturgefahrenGemabAZ6793: copyObject(jahreshochstentschadigungNaturgefahrenGemabAZ6793Object),
      SachenImGefahrenbereich: copyObject(sachenImGefahrenbereichObject),
      AltbauNachAZ6155: copyObject(altbauNachAZ6155Object),
      calculation: null,
      noPrice: false,
      price: 0,
      inclusionOfClientDamages,
      artGeboudeItemsAbu,
      pdfStyles: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'processSavingData', 'productId', 'id'],

  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData) {
        this.Beschreibung.BeschreibungObjektItems.Objekt1.Versicherungssumme = this.insuranceData.basicData.recordSumInsured?.Versicherungssumme;
      }
    },
  },

  methods: {
    onCurrencyFocus,
    exportToPDF() {
      const filename = `Zusammenfassung-Bauleistung-Tarif-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    setValues(insuranceData: any) {
      if (insuranceData.spreadsheetData !== null && this.key === this.insuranceKey) {
        const calculationData = insuranceData.spreadsheetData;
        this.Beschreibung = calculationData.Beschreibung;
        this.Selbstbeteiligung = calculationData.Selbstbeteiligung;
        this.Grundungsmabnahmen = calculationData.Grundungsmabnahmen;
        this.MitversicherungBesondererBaumabnahmen = calculationData.MitversicherungBesondererBaumabnahmen;
        this.Erstrisikosummen = calculationData.Erstrisikosummen;
        this.AZ6365VNAuftraggeber = calculationData.AZ6365VNAuftraggeber || {
          Tiefbaurisiko: null,
          GefahrteilungAbweichendVonVOBTeilB: null,
        };
        this.AZ6364EinschlussAuftraggeberschaden = calculationData.AZ6364EinschlussAuftraggeberschaden || copyObject(aZ6364EinschlussAuftraggeberschadenObject);
        this.Deckungseinschrankungen = calculationData.Deckungseinschrankungen;
        this.Deckungserweiterungen = calculationData.Deckungserweiterungen;
        this.MitversicherungGewasserrisiko = calculationData.MitversicherungGewasserrisiko || copyObject(mitversicherungGewasserrisikoObject);
        this.Nachhaftung = calculationData.Nachhaftung || copyObject(nachhaftungObject);
        this.MitversicherungVonFeuer = calculationData.MitversicherungVonFeuer;
        this.JahreshochstentschadigungNaturgefahrenGemabAZ6793 = calculationData.JahreshochstentschadigungNaturgefahrenGemabAZ6793 || copyObject(jahreshochstentschadigungNaturgefahrenGemabAZ6793Object);
        this.SachenImGefahrenbereich = calculationData.SachenImGefahrenbereich || copyObject(sachenImGefahrenbereichObject);
        this.AltbauNachAZ6155 = calculationData.AltbauNachAZ6155 || copyObject(altbauNachAZ6155Object);
      }
    },
    deleteErstrisikosummenItem(key) {
      this.Erstrisikosummen.additionalItems.splice(key, 1);
    },
    addErstrisikosummenAdditionalItem() {
      this.Erstrisikosummen.additionalItems.push({
        ...GrundwertAdditionalItem,
      });
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    onSubmit() {
      const data = {
        Beschreibung: this.Beschreibung,
        Selbstbeteiligung: this.Selbstbeteiligung,
        Grundungsmabnahmen: this.Grundungsmabnahmen,
        MitversicherungBesondererBaumabnahmen: this.MitversicherungBesondererBaumabnahmen,
        Erstrisikosummen: this.Erstrisikosummen,
        AZ6365VNAuftraggeber: this.AZ6365VNAuftraggeber,
        AZ6364EinschlussAuftraggeberschaden: this.AZ6364EinschlussAuftraggeberschaden,
        Deckungseinschrankungen: this.Deckungseinschrankungen,
        Deckungserweiterungen: this.Deckungserweiterungen,
        MitversicherungGewasserrisiko: this.MitversicherungGewasserrisiko,
        Nachhaftung: this.Nachhaftung,
        MitversicherungVonFeuer: this.MitversicherungVonFeuer,
        JahreshochstentschadigungNaturgefahrenGemabAZ6793: this.JahreshochstentschadigungNaturgefahrenGemabAZ6793,
        SachenImGefahrenbereich: this.SachenImGefahrenbereich,
        AltbauNachAZ6155: this.AltbauNachAZ6155,
      };
      this.$emit('spreadsheet-data-change', data, this.key);
      /* eslint-disable @typescript-eslint/camelcase */
      const data2 = {
        insuranceKey: this.key,
        price_data: this.calculation,
        // form_data: data,
        price_form_data: {
          price: this.price,
          noPrice: this.noPrice,
        },
      };
      this.$emit('price-card-data', data2, this.price, this.noPrice);
    },
  },
  computed: {
    showPlusBtn(): boolean {
      return (this.Erstrisikosummen.additionalItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.Erstrisikosummen.additionalItems.length > 1);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    this.$emit('on-key-change', this.key);
    (this as any).startWatcherActivated();
  },
  // computed: {},
});
